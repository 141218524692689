<template>
    <div class="continer">
        <img src="../../../assets/support/bg.jpg" alt="" class="bg">
        <img src="../../../assets/common/pic/logoRW.png" alt="" class="logo" @click="backHome">
        <div class="longinBox">
            <span class="tit">宁讯科技客户登陆页面</span>
            <form action="">
                <div>
                    <span class="accountTxt">账号</span>
                    <input type="text" class="account">
                </div>
                <div>
                    <span class="codeTxt">密码</span>
                    <input type="password" class="code" ref="password">
                </div>
                <button class="btn" @click="documentClick">登&nbsp;&nbsp;录</button>
            </form>
        </div>
        <div class="action">
            更多技术支持请联系地区合作SI商或邮件：support@linkom.com.cn<br>
            页面内所有说明资料及图片仅供参考，最终解释权归杭州宁讯信息科技有限公司所有
        </div>
    </div>
</template>

<script>
export default {
    name: 'login',
    methods: {
        backHome() {
            this.$router.replace('/')
        },
        documentClick() {
            window.console.log(this.$refs.password.value);
            if (this.$refs.password.value === '123456') {
                localStorage.setItem('token', 'document')
                this.$router.push('document')
            } else {
                localStorage.removeItem('token')
                alert('密码错误，请更正！')
            }
        }
    },
    created() {
        this.$nextTick(() => {
            // 1.禁用右键菜单
            document.oncontextmenu = new Function("event.returnValue=false");
            // 2.禁用鼠标选中
            document.onselectstart = new Function("event.returnValue=false");
            // 3.禁止键盘F12键
            document.addEventListener("keydown", function (e) {
                if (e.key == "F12") {
                    e.preventDefault(); // 如果按下键F12,阻止事件
                }
            });
        });
    }
}
</script>

<style scoped>
.continer {
    position: relative;
    background-image: url(../../../assets/support/bg.jpg);
    background-size: 1920px 1080px;
}

.continer .bg {
    float: left;
    width: 100vw;
    height: 100vh;
}

.continer .logo {
    z-index: 9999;
    position: absolute;
    top: 40px;
    left: 40px;
    width: 160px;
}

.continer .logo:hover {
    cursor: pointer;
}

.continer .longinBox {
    z-index: 999;
    position: absolute;
    right: 200px;
    top: 260px;
    padding: 20px;
    border-radius: 3px;
    width: 360px;
    height: 250px;
    text-align: center;
    background-color: #fff;
    box-shadow: 0 3px 5px 1px rgb(32 32 32 / 30%);
}

.continer .longinBox .tit {
    font-size: 18px;
    font-weight: 700;
    color: #988634;
}

.continer .longinBox form {
    margin-top: 30px;
}

.continer .longinBox .accountTxt {
    font-size: 14px;
    font-weight: 700;
    margin-right: 10px;
    color: #988634;
}

.continer .longinBox .account {
    height: 20px;
    border: 1px solid #333;
    margin-bottom: 10px;
}

.continer .longinBox .codeTxt {
    font-size: 14px;
    font-weight: 700;
    margin-right: 10px;
    color: #988634;
}

.continer .longinBox .code {
    height: 20px;
    border: 1px solid #333;
}

.continer .longinBox .btn {
    width: 80px;
    height: 30px;
    margin-top: 20px;
    margin-right: -90px;
    color: #fff;
    border-radius: 3px;
    font-size: 12px;
    border: none;
    background-color: #988634;
}

.action {
    position: absolute;
    right: 200px;
    top: 515px;
    text-align: right;
    font-size: 10px;
}
</style>
